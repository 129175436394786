// var menuItems = [];
let menu=[]
let menuItems= [
    {
        title: 'Country',
        route: 'country',
        codename:'add_product'
    },
    {
        title: 'District',
        route: 'district',
        codename:'change_product'
    },
    {
        title: 'Area',
        route: 'area',
        codename:'change_product'
    },
    {
        title: 'Industry Category',
        route: 'profession',
        codename:'change_product'
    },
    {
        title: 'Designation',
        route: 'position',
        codename:'change_product'
    },
    // {
    //     title: 'Organization',
    //     route: 'organization',
    //     codename:'change_product'
    // },
    {
        title: 'Degree',
        route: 'degree',
        codename:'change_product'
    },
    {
        title: 'Hobby',
        route: 'hobby',
        codename:'change_product'
    },
    // {
    //     title: 'Subject',
    //     route: 'subject',
    //     codename:'change_product'
    // },
    // {
    //     title: 'Institution',
    //     route: 'institution',
    //     codename:'change_product'
    // },
]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


if(menuItems.length!==0) {
    menu.push({
        title: 'Database Management',
        icon: 'DatabaseIcon',
        children: menuItems
    })
}
export default menu;