var menu =
    [
        {
            title: 'Advertisement',
            route: 'adportal', 
            icon: 'CodesandboxIcon',
        },
    ]

export default menu;
