// var menuItems = [];
let menu=[]
let menuItems= [
    {
        title: 'Android',
        route: 'android-users',
        codename:'add_product'
    },
    {
        title: 'IOS',
        route: 'ios-users',
        codename:'add_product'
    },
    {
        title: 'Online',
        route: 'online',
        codename:'add_product'
    },
    {
        title: 'High Activity',
        route: 'high-activity',
        codename:'add_product'
    },
    {
        title: 'Low Activity',
        route: 'low-activity',
        codename:'add_product'
    },
    {
        title: 'No Activity',
        route: 'no-activity',
        codename:'add_product'
    }
]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


if(menuItems.length!==0) {
    menu.push({
        title: 'User Behaviour List',
        icon: 'UserIcon',
        children: menuItems
    })
}
export default menu;
