// var menuItems = [];
let menu=[]
let menuItems= [
    {
        title: 'Admins',
        route: 'admins',
        codename:'add_product'

    },
    // {
    //     title: 'Env Editor',
    //     route: 'campaign-list',
    //     codename:'change_product'
    // },

    // {
    //     title: 'Notifications',
    //     route: 'gallery-add',
    //     codename:'add_product'
    // },

    // {
    //     title: 'Roles',
    //     route: 'project-list',
    //     codename:'change_product'
    // },

    // {
    //     title: 'Settings',
    //     route: 'settings',
    //     codename:'add_product'
    // },
    {
        title: 'User Reports',
        route: 'activity-log',
        codename:'add_product'
    },
    {
        title: 'Choose Viewers',
        route: 'choose-viewers',
        codename:'add_product'
    },
    {
        title: 'Edit By Admin',
        route: 'edit-by-admin',
        codename:'add_product'
    },
    {
        title: 'Faqs',
        route: 'faqs',
        codename:'change_product'
    },
    // {
    //     title: 'Mail Templates',
    //     route: 'gallery-list',
    //     codename:'change_product'
    // },
    {
        title: 'Contact Requests',
        route: 'contact-request',
        codename:'change_product'
    },
]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


if(menuItems.length!==0) {
    menu.push({
        title: 'Administration',
        icon: 'ShieldIcon',
        children: menuItems
    })
}
export default menu;
