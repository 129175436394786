let menu=[]
let menuItems=[
    {
        title: 'YouTube Videos',
        route: 'videos',
        codename:'add_product'

    },
    {
        title: 'Additional Settings',
        route: 'additional-settings',
        codename:'add_product'
    }
]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })


if(menuItems.length!==0) {
    menu.push({
        title: 'Settings',
        icon: 'SettingsIcon',
        children: menuItems
    })
}
export default menu;

